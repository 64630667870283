<!--  -->
<template>
    <el-row class="chargeCenter">
        <el-col>
            <el-scrollbar style="height: calc(100vh - 160px)">
                <el-card style="min-height: calc(100vh - 160px)">
                    <div slot="header" class="header">火币充值</div>
                    <el-tabs v-model="tab" @tab-click="handleTabClick">
                        <el-tab-pane label="个人充值" name="single">
                            <div class="charge-type-title">充值套餐</div>
                            <div class="card-box">
                                <div
                                    class="money-item"
                                    :class="{
                                        'money-item-selected':
                                            activeRechargeItem === item.id,
                                    }"
                                    v-for="(item, index) in goodsRechargeList"
                                    @click="
                                        activeRechargeItem = item.id;
                                        money = item.price / 100;
                                    "
                                    :key="item.id"
                                >
                                    <p v-if="item.giveAmount" class="card-tips">
                                        赠送{{
                                            Number(item.giveAmount) / payRate
                                        }}火币
                                    </p>
                                    <div class="title-1">
                                        <img
                                            src="@/assets/img/coin.png"
                                            alt=""
                                            style="width: 16px"
                                        />
                                        <div>
                                            {{
                                                Number(item.amount) / payRate +
                                                Number(item.giveAmount) /
                                                    payRate
                                            }}
                                        </div>
                                        <div style="font-size: 16px">火币</div>
                                    </div>
                                    <div class="title-2">
                                        ¥
                                        <span class="money-number">
                                            {{ item.price / 100 }}
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        {{ item.name }}
                                    </div>

                                    <img
                                        class="vip-card-icon"
                                        v-if="activeRechargeItem === item.id"
                                        src="@/assets/img/vip/vip-card-active-icon.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div
                                class="charge-type-title"
                                style="margin-top: 16px"
                            >
                                支付方式
                            </div>
                            <div class="charge-type">
                                <div style="display: flex">
                                    <div
                                        v-for="(item, index) in payMethods"
                                        :key="item.name"
                                        :class="index !== 0 ? 'marginLeft' : ''"
                                        class="payBlock"
                                        @click="payMethod = item.name"
                                    >
                                        <img
                                            :src="item.img"
                                            style="
                                                transform: translateX(5px);
                                                width: 42px;
                                                height: 42px;
                                            "
                                        />
                                        <div
                                            :class="
                                                payMethod === item.name
                                                    ? 'money-item-selected'
                                                    : ''
                                            "
                                            class="payName"
                                        >
                                            {{ item.cName }}
                                        </div>
                                        <img
                                            class="charge-type-select-icon"
                                            v-if="payMethod === item.name"
                                            src="@/assets/img/vip/vip-card-active-icon.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="charge-type-title">充值账号</div>
                            <div class="charge-account">
                                <div
                                    class="money-item change-account-item"
                                    :class="{
                                        'money-item-selected':
                                            accountType === item.type,
                                    }"
                                    v-for="(item, index) in payAccount"
                                    @click="handleSelectAccountUser(item.type)"
                                    :key="item.name"
                                >
                                    <div class="title-2 change-account-title">
                                        <span class="money-number">
                                            <img
                                                src="@/assets/img/coin.png"
                                                alt=""
                                                style="width: 20px"
                                            />
                                            {{
                                                Number(
                                                    accountInfo[item.name]
                                                        .balance,
                                                ) / payRate
                                            }}
                                            <span style="font-size: 20px">
                                                火币
                                            </span>
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        {{ item.cName }}
                                    </div>

                                    <img
                                        class="vip-card-icon"
                                        v-if="accountType === item.type"
                                        src="@/assets/img/vip/vip-card-active-icon.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: 4rem;
                                    margin-top: 2rem;
                                "
                            >
                                <div style="display: flex; align-items: center">
                                    <div class="title">实付金额：</div>
                                    <div
                                        v-if="money"
                                        style="
                                            font-size: 2.99rem;
                                            padding-top: 0.6rem;
                                            color: #e86b49;
                                        "
                                    >
                                        ￥{{ money }}
                                    </div>
                                </div>
                                <div style="display: flex; align-items: center">
                                    <el-checkbox
                                        v-model="checked"
                                        text-color="#136bf9"
                                        fill="#136bf9"
                                    >
                                        同意
                                    </el-checkbox>
                                    <paymentPopup
                                        @dialogChecked="dialogChecked"
                                    />
                                    <el-button
                                        :loading="submitLoading"
                                        style="
                                            background: #136bf9;
                                            color: white;
                                            padding: 0.5rem 1rem;
                                            margin: 0 1rem;
                                        "
                                        @click="confirmToCharge()"
                                    >
                                        确定充值
                                    </el-button>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="对公转账" name="company">
                            <div class="charge-type-title">充值套餐</div>
                            <div class="card-box">
                                <div
                                    class="money-item"
                                    :class="{
                                        'money-item-selected':
                                            activeRechargeItem === item.id,
                                    }"
                                    v-for="(item, index) in goodsRechargeList"
                                    @click="
                                        activeRechargeItem = item.id;
                                        money = item.price / 100;
                                    "
                                    :key="item.id"
                                >
                                    <p v-if="item.giveAmount" class="card-tips">
                                        赠送{{
                                            Number(item.giveAmount) / payRate
                                        }}火币
                                    </p>
                                    <div class="title-1">
                                        <img
                                            src="@/assets/img/coin.png"
                                            alt=""
                                            style="width: 16px"
                                        />
                                        <div>
                                            {{
                                                Number(item.amount) / payRate +
                                                Number(item.giveAmount) /
                                                    payRate
                                            }}
                                        </div>
                                        <div style="font-size: 16px">火币</div>
                                    </div>
                                    <div class="title-2">
                                        ¥
                                        <span class="money-number">
                                            {{ item.price / 100 }}
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        {{ item.name }}
                                    </div>

                                    <img
                                        class="vip-card-icon"
                                        v-if="activeRechargeItem === item.id"
                                        src="@/assets/img/vip/vip-card-active-icon.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div
                                class="charge-type-title"
                                style="margin-top: 20px"
                            >
                                对公账户信息
                            </div>
                            <div
                                style="
                                    font-size: 14px;
                                    font-weight: 800;
                                    line-height: 24px;
                                "
                            >
                                <div>宁波火箭数据科技有限公司</div>
                                <div>纳税识别号:91330283MA2CJUJ40W</div>
                                <div>
                                    地址:浙江省宁波市鄞州区邱隘镇沈家村晨源大楼8-02-106室
                                </div>
                                <div>电话:0574-27836910</div>
                                <div>
                                    开户行及账号:工商银行宁波新城支行
                                    3901120109200118913
                                </div>
                            </div>
                            <div
                                class="charge-type-title"
                                style="margin-top: 10px"
                            >
                                转账截图
                            </div>
                            <el-upload
                                :action="actionUrl"
                                class="avatar-uploader"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :headers="uploadHeaders"
                            >
                                <img
                                    v-if="transImg"
                                    :src="transImg"
                                    class="avatar"
                                />
                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                            </el-upload>
                            <div
                                class="charge-type-title"
                                style="margin-top: 10px"
                            >
                                开票信息
                                <span
                                    style="
                                        margin-left: 20px;
                                        color: red;
                                        cursor: pointer;
                                    "
                                    @click="handleSettingOpenTicketInfo"
                                >
                                    设置
                                </span>
                            </div>
                            <div
                                v-if="invoiceName"
                                style="
                                    font-size: 14px;
                                    font-weight: 800;
                                    line-height: 24px;
                                "
                            >
                                开票企业:{{ invoiceName }}
                            </div>

                            <div style="display: flex; justify-content: center">
                                <el-button
                                    @click="handleChargeCompany"
                                    :loading="submitLoading"
                                    type="primary"
                                    size="small"
                                >
                                    充值
                                </el-button>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-scrollbar>
        </el-col>
        <!-- 微信支付弹框 -->
        <el-dialog
            :visible.sync="buyCode"
            width="460px"
            @close="buyClose"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :show-close="false"
            class="paymentDialog"
        >
            <template slot="title">
                <div class="header-title">
                    <div style="color: white; font-size: 20px">
                        微信扫一扫充值火币
                    </div>
                    <div class="close-btn">
                        <i
                            class="el-icon-circle-close"
                            @click="buyCode = false"
                        ></i>
                    </div>
                </div>
            </template>
            <div
                class="buy_box dis_f_c_c"
                style="
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                "
            >
                <div style="margin-bottom: 20px; font-size: 24px">
                    支付金额:
                    <span style="color: #e86b49; font-size: 28px">
                        ￥{{ money }}
                    </span>
                </div>
                <div id="qrcode" ref="qrcode" class="img1 dis_f_c_c"></div>
                <el-button type="info" round style="margin-top: 30px">
                    距离二维码过期还剩下{{ dTime }}秒
                </el-button>
            </div>
        </el-dialog>
        <PayMentTicketInfoDialog
            ref="payMentTicketInfoDialogRef"
            @data="
                (param) => (
                    (invoiceId = param.id), (invoiceName = param.companyName)
                )
            "
        />
    </el-row>
</template>

<script>
import QRCode from "qrcodejs2";
import { payMethods, payAccount, payRate } from "@/utils/pay-data-list";
import paymentPopup from "../../PayMent/component/PayMentPopup.vue";
import {
    payRechargeGoods,
    getAccount,
    rechargeOrderPay,
    queryOrderByOrderNo,
    chargeCompanyAccount,
} from "@/api/pay";
import PayMentTicketInfoDialog from "./PayMentTicketInfoDialog.vue";
import { getToken, getCompanyId } from "@/utils/auth";
export default {
    components: { paymentPopup, PayMentTicketInfoDialog },
    data() {
        return {
            payAccount: [],
            payMethods: payMethods,
            goodsRechargeList: [],
            activeRechargeItem: null,
            accountInfo: null,
            accountType: null,
            accountId: null,
            payMethod: "WXPAY",
            buyCode: false,
            buyCodeZFB: false,
            moneyHtml: "",
            checked: false,
            money: null,
            times: 0,
            dTime: 300,
            interval: null,
            submitLoading: false,
            payRate,
            tab: "single", //company single分别对应个人和对公转账
            uploadHeaders: { Authorization: getToken() },
            transImg: "",
            invoiceId: 0,
            invoiceName: "",
        };
    },

    methods: {
        // 图片上传成功
        handleAvatarSuccess(response) {
            this.transImg = response.data.url;
        },
        // 设置开票信息
        handleSettingOpenTicketInfo() {
            this.$refs.payMentTicketInfoDialogRef.init();
        },
        // tab切换
        handleTabClick(tab) {
            this.$refs.payMentTicketInfoDialogRef.init(false);
        },
        handleChargeCompany() {
            if (!this.money) {
                this.$message.warning("请选择充值金额");
                return;
            }
            if (!this.transImg) {
                this.$message.warning("请上传转账截图");
                return;
            }
            if (!this.invoiceId) {
                this.$message.warning("请设置开票信息");
                return;
            }
            const param = {
                amount: this.money,
                billPicture: this.transImg,
                invoiceId: this.invoiceId,
                goodId: this.activeRechargeItem,
            };
            this.submitLoading = true;
            chargeCompanyAccount(param)
                .then(() => {
                    this.$message.success("对公转账信息提交成功,等待审核");
                })
                .finally(() => {
                    this.submitLoading = false;
                });
        },
        dialogChecked(data) {
            this.checked = data;
        },
        buyClose() {
            this.times = 0;
            this.dTime = 300;
            this.interval && clearInterval(this.interval);
            this.interval = null;
            this.timeInterval && clearInterval(this.timeInterval);
            this.timeInterval = null;
        },
        handleSelectAccountUser(type) {
            this.accountType = type;
            this.accountId =
                type === 0
                    ? this.accountInfo.accountUser.id
                    : this.accountInfo.accountCompany.id;
        },
        confirmToCharge() {
            if (this.checked !== true) {
                this.$message.warning("请先同意《火币充值用户条例》");
                return;
            }
            if (isNaN(parseFloat(this.accountType))) {
                this.$message.warning("请选择充值账户");
                return;
            }
            if (!this.activeRechargeItem) {
                this.$message.warning("请选择充值金额");
                return;
            }
            const param = {
                accountId: this.accountId,
                accountType: this.accountType,
                goodsId: this.activeRechargeItem,
                payType: this.payMethod,
            };
            this.submitLoading = true;
            rechargeOrderPay(param)
                .then(({ data }) => {
                    // 请求成功后打开轮询(5分钟超时)
                    if (!this.interval) {
                        this.interval = setInterval(() => {
                            this.times++;
                            if (this.times > 99) {
                                this.times = 0;
                                this.dTime = 300;
                                this.buyCode = false;
                                this.$AlertTipsDialog({
                                    msg: "支付已超时，请重新支付",
                                });
                                clearInterval(this.interval);
                                this.interval = null;
                                return;
                            }
                            queryOrderByOrderNo({
                                orderNo: data.data.orderNo,
                            }).then(({ data }) => {
                                if (data.data?.status === "SUCCESS") {
                                    this.buyCode = false;
                                    this.times = 0;
                                    this.dTime = 300;
                                    this.$message.success("充值成功");
                                    this.getUserAccountInfo();
                                    clearInterval(this.interval);
                                    this.interval = null;
                                } else if (data.data?.status === "CLOSED") {
                                    this.buyCode = false;
                                    this.times = 0;
                                    this.dTime = 300;
                                    this.$AlertTipsDialog({
                                        msg: "交易已关闭",
                                    });
                                    clearInterval(this.interval);
                                    this.interval = null;
                                }
                            });
                        }, 3000);
                    }

                    if (param.payType === "WXPAY") {
                        this.timeInterval = setInterval(() => {
                            this.dTime--;
                        }, 1000);
                        this.buyCode = true;
                        this.$nextTick(() => {
                            this.qrcode = new QRCode("qrcode", {
                                width: 280, //二维码宽度
                                height: 280, //二维码高度
                                text: data.data.payInfo, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr=uDKsQ4E00"
                                colorDark: "#79031d", //颜色配置
                                colorLight: "#fff",
                            });
                        });
                    } else {
                        const div = document.createElement("div");
                        div.innerHTML = data.data.payInfo;
                        document.body.appendChild(div);
                        document.forms[0].target = "_blank";
                        document.forms[0].submit();
                        document.body.removeChild(div);
                    }
                })
                .finally(() => {
                    this.submitLoading = false;
                });
        },
        getUserAccountInfo() {
            // 是否不是企业账户
            const isNotCompany =
                !this.$store.state.user.loginId ||
                this.$store.state.user.loginId === "personal";
            const param = {};
            if (!isNotCompany) {
                param.companyId = getCompanyId();
            }
            getAccount(param)
                .then(({ data }) => {
                    this.accountInfo = data.data;
                    console.log(data, "---data");
                    this.payAccount = payAccount.filter((item) => {
                        if (isNotCompany) {
                            this.accountType = 0;
                            this.accountId = this.accountInfo.accountUser.id;
                            return (
                                this.accountInfo[item.name] &&
                                item.name !== "accountCompany"
                            );
                        } else {
                            this.accountType = 1;
                            this.accountId = this.accountInfo.accountCompany.id;
                            return (
                                this.accountInfo[item.name] &&
                                item.name !== "accountUser"
                            );
                        }
                    });
                    this.$store.commit(
                        "user/edituAccount",
                        data.data?.accountUser?.balance ?? 0,
                    );
                    this.$store.commit(
                        "user/editcAccount",
                        data.data?.accountCompany?.balance ?? 0,
                    );
                })
                .catch(() => {
                    this.$store.commit("user/edituAccount", 0);
                    this.$store.commit("user/editcAccount", 0);
                });
        },
    },
    mounted() {
        payRechargeGoods().then(({ data }) => {
            this.goodsRechargeList = data.data.map((item) => {
                // const replaceName = item.name.includes("送")
                //     ? item.name.split("送")[0] +
                //       "送" +
                //       (Number(item.name.split("送")[1]) / this.payRate) * 10
                //     : item.name;
                const replaceName = `充值${Number(item.amount) / this.payRate}${
                    item.giveAmount
                        ? `送` + Number(item.giveAmount) / this.payRate
                        : ""
                }`;
                return {
                    ...item,
                    name: replaceName,
                };
            });
        });
        this.getUserAccountInfo();
    },
    beforeDestroy() {
        this.interval && clearInterval(this.interval);
        this.interval = null;
        this.timeInterval && clearInterval(this.timeInterval);
        this.timeInterval = null;
    },
    computed: {
        // 媒体上传url
        actionUrl() {
            // return `${axiosFileEnum[process.env.VUE_APP_API_ENV]}upload`;
            return `${this.$base_url}/file/upload`;
        },
    },
};
</script>
<style scoped>
/deep/.avatar-uploader .el-upload {
    border: 2px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
.avatar {
    width: 100px;
    height: 100px;
    display: block;
}
</style>
<style scoped lang="stylus">
.header-title{
    background: linear-gradient(to right, #de5d40, #e5917e, #daacb9);
    height:40px;
    border-radius:10px 10px 0 0;
    display:flex;
    align-items:center;
    position:relative;
    justify-content:center;
    .close-btn{
        position: absolute;
        right:10px;
        color:white;
        font-size:24px;
        cursor: pointer;
    }
}

.paymentDialog{
    /deep/ .el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-dialog__body{
        background:linear-gradient(#ffb4b6, 1%, white, white, white, white) !important;
    }
    /deep/ .el-dialog__header{
        padding: 0;
    }
}
/deep/ .el-scrollbar__wrap
{
  overflow-x: hidden;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
    color:#136bf9;
}
/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
    padding:1.68rem;
}
.active {
    border: 2px solid #136BF9 !important;
}

.activeL{
    border: 2px solid #136BF9 !important;
    border-left:0 !important;
}
.chargeCenter {
    display: flex;
    min-width:800px;
    justify-content: center;
}

.money{
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height:12rem
}
.block {
    width: calc(25% - 6rem);
    height:10rem;
    border: 2px solid #d3d3d3;
    font-size:1.68rem;
    text-align: center;
    border-radius:5px;
    display:flex;
    flex-direction:column;
    margin-right: 2.94rem;
    margin-bottom: 1.87rem;
    position:relative;
    cursor:pointer;
}

.top{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
    margin:0 0.5rem;
    border-bottom: 1px dashed #d0d4d8;
}

.amount{
    color: #9eaabd;
    font-size: 1.68rem;
    font-weight: 500;
    line-height:2.24rem;
    font-family: Inter-Medium, Inter;
}

.arigle {
    width: 0;
    height: 0;
    border-color: #136bf9 transparent;
    border-width: 0px 0px 2.8rem 2.8rem;
    border-style: solid;
    position: absolute;
    right: 0;
    bottom:0;
}

.gift{
    position: absolute;
    right:0;
    top:2px;
}

.giftName{
    position: absolute;
    right:0.85vw;
    top:calc(2px + 0.1vw);
}
.check {
    position: absolute;
    color: white;
    right: 2px;
    bottom: 2px;
    font-size: 1.12rem;
}

.tips{
    margin-left: 100px;
    width: calc(100% - 13.5rem);
    min-height: 5vh;
    border: 1px solid #fac862;
    background: #fdf8e8;
    border-radius: 5px 5px 5px 5px;
    color: #f6a63d;
    padding: 0 5px;
    font-size: 1.31rem;
}

.marginLeft{
    margin-left:2rem;
}

.payBlock{
    display: flex;
    align-items: center;
    height: 4.205rem;
    width: 23.36rem;
    cursor: pointer;
    position: relative;

}

.payMoney{
    color: #e86b49;
    font-size: 1.5rem;
    display: flex;
    font-weight:700;
    align-items: center;
    justify-content: center;

    height: 40%;
}

.payName{
    height: 38px;
    color: #b87100;;
    font-size: 16px;
    line-height: 38px;
    flex: 1;
    border: 2px solid #fae8d0;
    border-left: 0;
    text-align: center;
    border-radius:0 8px 8px 0;
}

.title {
    width: 9.346rem;
    height: 2.056rem;
    font-size: 1.5rem;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    color: #989DA6;
    padding-top:0.5rem;
}

.header {
    color: white;
    font-size: 1.68rem;
    font-weight: 700;
}


.card-box{
    display: flex;
    flex-wrap:wrap;
    row-gap:20px;
    padding:0px 8px;
}


.card-tips{
    position: absolute;
    padding: 0 4px;
    height: 24px;
    line-height: 24px;
    background: -webkit-gradient(linear,left top,right top,from(#fc5531),to(#fc1944));
    background: linear-gradient(90deg,#fc5531,#fc1944);
    border-radius: 6px 0 8px 0;
    color: #fff;
    left: -2px;
    top: -12px;
}

.money-item{
    width: 204px;
    height: 134px;
    border-radius: 8px;
    border: 2px solid #fae8d0;
    background-color: #fff;
    position: relative;
    text-align: center;
    margin-right: 26px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    cursor: pointer;
}


.change-account-item{
    height:100px
}

.money-item-selected{
    border-color: #b87100;
    background-color: #fff9f1;
}

.vip-card-icon{
    position: absolute;
    right: -3px;
    bottom:0;
    width: 24px;
    height: 24px;
    z-index: 3;
}

.charge-type-select-icon{
    position: absolute;
    right: 0px;
    bottom:0;
    width: 16px;
    height: 16px;
    z-index: 3;
}

.title-1{
    font-size: 20px;
    height: 24px;
    line-height: 24px;
    color: #222226;
    font-weight: 700;
    margin: 24px 0 12px;
    display:flex;
    justify-content:center;
    align-items:center;
    position: relative;
    z-index: 2;
}

.title-2{
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    display:flex;
    justify-content:center;
    align-items:center;
    color: #b87100;
    position: relative;
    z-index: 2;
}

.money-number{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 24px;
    font-weight: 700;
}
.card-footer{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 32px;
    background-color: #fae8d0;
    font-size: 12px;
    line-height: 32px;
    color: #69421b;
    font-weight: 500;
    bottom: 0;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
}

.charge-type{
    border-radius: 8px;
    height: 60px;
    padding: 0 8px;
    &-title{
        font-size: 16px;
        line-height: 22px;
        color: #222226;
        font-weight: 700;
        margin-bottom: 16px;
    }
}

.charge-account{
    border-bottom: 2px solid #d3d3d3;
    padding:0px 8px;
    padding-bottom:16px;
}

.change-account-title{
    margin-top:20px
}
</style>
